<template>
  <div class="subpage">

    <FrameMD
        :posY="posY"
        :transitionPhoto="false"
        :staticPhoto="true"
        :photo="pageContent.section0.photo"
        :photoHeight="'500px'"
        :header="pageContent.section0.header"
        :text="pageContent.section0.text"
    ></FrameMD>
    
    
    <div class="header" ref="header">wybierz tematykę</div>
    <div class="themes-container" ref="themes">
      <router-link
        exact
        v-for="(theme, index) in poemsThemes" :key="index"
        :to="{ name: 'PoemsBox', params: { theme: theme.link } }"
      >{{ theme.name }}</router-link>
      <router-link
        exact
        v-if="newestNotEmpty"
        :to="{ name: 'PoemsBox', params: { theme: newestOptions.link } }"
      >{{ newestOptions.name }}</router-link>
    </div>

    <div class="container">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </div>
    

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameMD from '@/components/FrameMD'

export default {
  name: 'Poems',
  props: ['posY'],
  components: {FrameMD},
  data () {
      return {
        
      }
  },
  computed: {
    ...mapState({
      pageContent: state => state.data.poems,
      poems: state => state.poems
    }),
    ...mapGetters(['poemsThemes']),
    newestNotEmpty () {
      return this.poems.some(el => {
        return el.newest === true
      })
    },
    newestOptions () {
      return this.pageContent.newest
    }
  },
  methods: {
    checkSticky () {
      if (this.posY >= this.$refs.themes.offsetTop) {
        this.$refs.themes.style.boxShadow = '0px 15px 10px -15px #555'
      }
      else {
        this.$refs.themes.style.boxShadow = 'none'
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.checkSticky)
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name !== 'Poems') {
      window.scroll({top: this.$refs.header.offsetTop, behavior: 'smooth'})
    }
    setTimeout(() => {
      next()
    }, 500);
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkSticky)
  }
}

</script>

<style lang="scss" scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 50px auto 100px auto;
}
.header {
    margin: 20px 0 0 0;
    font-family: "PT Serif", serif;
    font-size: 1.7rem;
    color: #EB7054;
    text-align: center;
    background-color: white;
    @media screen and (min-width: 600px) {
        margin: 20px 0 15px 0;
    }
    @media screen and (min-width: 1200px) {
        margin: 80px 0 15px 0;
        font-size: 2.5rem;
    }
}
.themes-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  top: 70px;
  background-color: white;
  z-index: 2;
  transition: all .25s ease;
  @media screen and (min-width: 600px) {
      position: sticky;
  }
  a {
    display: inline-block;
    margin: 5px 5px;
    padding: 10px;
    font-family: 'PT Serif', serif;
    font-size: 1.8rem;
    letter-spacing: 1px;
    color: #333333;
    transition: all .75s;
    &:hover {
        background-color: #EB7054;
        color: #FFFFFF;
        cursor: pointer;
    }
  }
}

</style>